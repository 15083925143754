import React, { FC } from 'react'
import './ClubsItem.scss'
import { BaseSvg } from '../index'
import green from '../../assets/image/green.png'
import AppLink from '../Base/AppLink'
import Badge from '../Badge/Badge'
import moment from 'moment/moment'

interface IProps {
  id: string | number
  name: string
  address: string
  image: string
  new: boolean
  not_working: boolean
  created_at: string
}

const ClubsItem: FC<IProps> = (props) => {
  const date = moment(props.created_at)
  const now = moment()

  const replaceByDefault = (e) => {
    e.target.src = green
  }

  return (
    <AppLink
      to={`/club/${props.id}/`}
      className={`clubs-item ${
        date > now || props.not_working ? '_darkening' : ''
      } ${props.not_working ? ' _bold' : ''}`}
    >
      <BaseSvg className="clubs-item__logo" name="ico-logo-text" />
      <h2 className="clubs-item__title">{props.name}</h2>
      <p className="clubs-item__address">{props.address}</p>
      <div className="clubs-item__img">
        <img src={props.image} onError={replaceByDefault} alt={props.name} />
        <Badge
          new={props.new}
          not_working={props.not_working}
          created_at={props.created_at}
          className="clubs-item__badge"
        />
        <BaseSvg
          name="ico-arrow-right-short"
          className="clubs-item__arrow d-md-none"
        />
      </div>
    </AppLink>
  )
}

export default ClubsItem
