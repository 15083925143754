import React, { useRef } from 'react'
import './Button.scss'
import AppLink from '../Base/AppLink'
import { BaseSvg } from '../index'

interface IProps {
  children?: React.ReactNode
  to?: string
  className?: string
  color?: '_orange' | '_green'
  onClick?: () => void
}

const Button = ({
  children,
  to,
  className,
  color = '_orange',
  onClick,
  ...props
}: IProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      {to ? (
        <AppLink
          ref={buttonRef}
          className={`button ${className ? className : ''} ${color} ${
            className ? className : ''
          }`}
          {...props}
          to={to}
        >
          <span className="button__text">
            {children}
            <BaseSvg name="ico-arrow-right" className="button__svg d-md-none" />
          </span>
          <span className="button__empty"></span>
          <BaseSvg
            name="ico-arrow-right"
            className="button__svg _contrast d-md-none"
          />
        </AppLink>
      ) : (
        <button
          ref={buttonRef}
          className={`button ${className ? className : ''} ${color} ${
            className ? className : ''
          }`}
          onClick={onClick}
          {...props}
        >
          <span className="button__text">
            {children}
            <BaseSvg name="ico-arrow-right" className="button__svg d-md-none" />
          </span>
        </button>
      )}
    </>
  )
}

export default Button
