import React, { FC, memo } from 'react'
import {
  BlogInner,
  ButtonArrowRight,
  ButtonSlider,
  Container,
} from '../../index'
import './Blog.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import { IBlogs } from '../../../models/Blogs'
import { useTranslation } from 'react-i18next'

SwiperCore.use([Navigation])

interface IProps {
  blogs: IBlogs[]
}

const Blog: FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <section className="home-blog">
      <Container>
        <div className="home-blog__body">
          <div className="home-blog__top">
            <h2 className="home-blog__title"> {t('pulse_gym_blog')}</h2>
            <ButtonArrowRight
              to="/information"
              className="home-blog__more d-md-none"
            >
              {t('see_all')}
            </ButtonArrowRight>
          </div>

          <Swiper slidesPerView="auto" className="home-blog__bottom d-md-none">
            {props.blogs.map((item: IBlogs) => (
              <SwiperSlide key={item.id} className="home-blog__bottom-item">
                <BlogInner
                  id={item.slug || null}
                  image={item.image}
                  tags={item.tags}
                  title={item.title}
                  date={item.date}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            className="home-blog__slider d-none d-md-block"
            navigation={{
              nextEl: '.home-blog__btn-next',
              prevEl: '.home-blog__btn-prev',
            }}
          >
            {props.blogs.map((item: IBlogs) => (
              <SwiperSlide className="home-blog__slide" key={item.id}>
                <BlogInner
                  key={item.id}
                  id={item.slug || null}
                  image={item.image}
                  tags={item.tags}
                  title={item.title}
                  date={item.date}
                />
              </SwiperSlide>
            ))}
            <div className="home-blog__wrapper">
              <ButtonSlider
                type="next"
                className="home-blog__btn home-blog__btn-next"
              />
              <ButtonSlider
                type="prev"
                className="home-blog__btn home-blog__btn-prev"
              />
            </div>
          </Swiper>
          <ButtonArrowRight
            isModMobile
            to="/information"
            className="d-none d-md-flex home-blog__more"
          >
            {t('see_all_articles')}
          </ButtonArrowRight>
        </div>
      </Container>
    </section>
  )
}

export default memo(Blog)
