import React, { FC, memo } from 'react'
import { Container } from '../index'
import './Trainer.scss'
import TrainerList from './TrainerList'
import TrainerSocial from './TrainerSocial'
import { ITrainer } from '../../models/Trainer'

interface IProps {
  isNameTitle?: boolean
  position?: string
  trainer: ITrainer
  className?: string
}

const Trainer: FC<IProps> = (props) => {
  return (
    <div
      className={`trainer ${props.className} ${
        props.trainer?.is_personal_coach ? '_mod' : ''
      }`}
    >
      <Container>
        <div className="trainer__body">
          <div className="trainer__left">
            <img src={props.trainer.image} alt="" />
          </div>
          <div className="trainer__right">
            {props?.position && (
              <div className="trainer__position d-none d-md-block">
                {props.position}
              </div>
            )}

            {props.trainer?.is_personal_coach ? (
              <div className="trainer__title">
                {props.isNameTitle ? props.trainer.name : props.trainer.title}
              </div>
            ) : null}
            {!props.trainer?.is_personal_coach && (
              <div className="trainer__name d-none d-md-flex">
                {props.trainer.name}
              </div>
            )}

            <TrainerSocial
              phone={props.trainer?.phone}
              social={props.trainer?.social}
              className="d-none d-md-flex"
            />
            {!props.trainer?.is_personal_coach && (
              <div className="trainer__title">
                {props.isNameTitle ? props.trainer.name : props.trainer.title}
              </div>
            )}

            {props.trainer?.description && (
              <div className="trainer__description">
                {props.trainer?.description}
              </div>
            )}

            {props.trainer?.specialization && (
              <TrainerList items={props.trainer.specialization} />
            )}

            <TrainerSocial
              phone={props.trainer?.phone}
              social={props.trainer?.social}
              className="d-md-none"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

Trainer.defaultProps = {
  className: '',
  isNameTitle: false,
}

export default memo(Trainer)
