import React, {useEffect, useState} from 'react'
import BaseInput from "../Base/BaseInput/BaseInput";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {RootState} from "../../store";
import {clearErrors, sendFeedback} from "../../store/slices/ask-feedback";
import {fetchClubs} from "../../store/slices/clubs";
import BaseSelect from "../Base/BaseSelect/BaseSelect";
import Modal from "react-modal";
import useBasicStyleModal from "../../helpers/getBasicStyleModal";
import {toast} from "react-toastify";
import chatIcon from "../../images/chat.svg";

const FeedbackForm = () => {
    const [open, setOpen] = useState(false);
    const {basicStyleModal} = useBasicStyleModal()
    const {t} = useTranslation()
    const {clubs} = useAppSelector((state: RootState) => state.clubs)

    const {errors} = useAppSelector((state: RootState) => state.feedback)

    const dispatch = useAppDispatch()

    const state = {
        club_id: '',
        name: '',
        phone: '',
        email: '',
        message: '',
    }

    const {register, handleSubmit, setValue} = useForm<any>({
        mode: 'onSubmit',
        defaultValues: state,
    })

    const onSubmit = (data: any) => {
        dispatch(sendFeedback(data))
            .unwrap()
            .then(() => {
                handleFormSuccessful()
            })
    }

    const callBack = (val: number) => {
        setValue('club_id', val)
    }

    useEffect(() => {
        dispatch(fetchClubs())
        return (): any => dispatch(clearErrors())
    }, [])

    const handleOpenFormClick = () => {
        setOpen(!open)
    }

    const handleCloseForm = () => {
        setOpen(false)
    }

    const handleFormSuccessful = () => {
        toast.success(t('ask_your_question_success'))
        handleCloseForm()
    }

    return (
        <div className={`feedback-form ${open ? 'open' : ''}`}>
            <button onClick={handleOpenFormClick} className={'feedback-form__button'}>
                <img
                    src={chatIcon}
                    alt="Chat"
                    className={'feedback-form__button-img'}
                />
            </button>

            <Modal
                isOpen={open}
                onRequestClose={handleCloseForm}
                contentLabel="Write a feedback"
                closeTimeoutMS={200}
                preventScroll={true}
                style={basicStyleModal}
                bodyOpenClassName="_scroll-lock"
                portalClassName="modal-portal"
                className={'feedback-form__container'}
            >
                <div className="write-feedback">
                    <div className="write-feedback__inner">
                        <div className="write-feedback__close" onClick={handleCloseForm}>
                        </div>
                        <div className="write-feedback__title">
                            {t('ask_your_question')}
                        </div>
                        <div className="write-feedback__description">
                            {t('ask_your_question_description')}
                        </div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="write-feedback__form"
                        >
                            <BaseSelect
                                options={clubs}
                                value={state.club_id}
                                name="club_id"
                                register={register}
                                callBack={callBack}
                                errors={errors}
                                className="write-feedback__select"
                            />
                            <BaseInput
                                name="name"
                                type="text"
                                register={register}
                                placeholder={t('your_name') || ''}
                                errors={errors}
                                className="write-feedback__input"
                            />
                            <BaseInput
                                name="phone"
                                type="text"
                                register={register}
                                placeholder={t('your_phone') || ''}
                                errors={errors}
                                className="write-feedback__input"
                            />
                            <BaseInput
                                name="email"
                                register={register}
                                type="email"
                                placeholder={t('your_email') || ''}
                                errors={errors}
                                className="write-feedback__input"
                            />
                            <BaseInput
                                textarea
                                name="message"
                                register={register}
                                errors={errors}
                                placeholder={t('feedback_text') || ''}
                                className="write-feedback__input"
                            />
                            <button type="submit" className="write-feedback__btn btn _orange">
                                {t('ask_feedback_publish')}
                            </button>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default FeedbackForm
