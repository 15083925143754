import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit'
import {feedbackReducers} from './slices/feedback'
import {blogsReducers} from './slices/blogs'
import {blogReducers} from './slices/blog'
import {loadingReducers} from './slices/loading'
import {tagsReducers} from './slices/tags'
import {clubsReducers} from './slices/clubs'
import {clubReducers} from './slices/club'
import {regulationsReducers} from './slices/regulations'
import {trainerReducers} from './slices/trainer'
import {promoReducers} from './slices/promo'
import {smartFitReducers} from './slices/smartFit'
import {settingsReducer} from "./slices/settings";
import {askFeedbackReducers} from "./slices/ask-feedback";
import {clubInfoReducers} from "./slices/club-info";

export const store = configureStore({
    reducer: {
        loading: loadingReducers,
        feedback: feedbackReducers,
        askFeedback: askFeedbackReducers,
        blogs: blogsReducers,
        blog: blogReducers,
        tags: tagsReducers,
        clubs: clubsReducers,
        club: clubReducers,
        regulations: regulationsReducers,
        trainer: trainerReducers,
        promo: promoReducers,
        clubInfo: clubInfoReducers,
        smartFit: smartFitReducers,
        settings: settingsReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
