import { ISeo } from './Seo'

export interface IClubs {
  address: string
  coaches: ICoaches[]
  id: number
  image: string
  lat: string
  lng: string
  media: IMedia[]
  name: string
  new: boolean
  not_working: boolean
  created_at: string
  phone: string[]
  services: IServices[]
  slug: string
  schedule: ISchedule
  price: IPrice[]
  scheduler: string
  seo: ISeo
  image_bar: string
  slugs: {
    ru: string
    uk: string
  }
  g_maps_url?: string
}

export interface IPrice {
  id: number
  actia?: boolean
  color?: string
  comment?: string | null
  name: string
  new?: boolean
  price: ISubPrice[]
  baner: IBaner[]
}

export interface IBaner {
  sub_title: string
  slug: string
  title: string
  mobile_title: string
  mobile_sub_title: string
  type: 'freeze' | 'two-free-training' | 'personal-coach'
}

export interface ISubPrice {
  comment: null | string
  id: number
  month: boolean
  name: string
  price: string
}

export interface ISchedule extends Array<any> {
  [key: number]: Array<IScheduleItem>
}

export interface IScheduleItem {
    service: string
    monday: [string],
    tuesday: [string],
    wednesday: [string],
    thursday: [string],
    friday: [string],
    sunday: [string],
    sort: number,
    recruiting: boolean,
}

export enum DaysOfWeekEnum {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export enum DaysOfWeekNumsEnum {
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
    sunday = 7,
}

export interface IMedia {
  collection_name: string
  conversions_disk: string
  created_at: string
  custom_properties: []
  disk: string
  file_name: string
  generated_conversions: {
    thumb: boolean
  }
  id: number
  manipulations: []
  mime_type: string
  model_id: number
  model_type: string
  name: string
  order_column: number
  original_url: string
  preview_url: string
  responsive_images: []
  size: number
  updated_at: string
  uuid: string
}

export interface IServices {
  name: string
}

export interface ICoaches {
  description: string
  id: number
  image: string
  name: string
  phone: null
  slug: string
  social: null
  title: string
}
