import React from 'react'
import { Container } from '../index'
import './BreadCrumbs.scss'
import '../../assets/scss/_utils.scss'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import AppLink from '../Base/AppLink'
import { useTranslation } from 'react-i18next'

interface Props {
  dataList: IBreadCrumbs[]
  className?: string
}

const BreadCrumbs = ({ dataList, className }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={`bread-crumbs d-md-none ${className ? className : ''}`}>
      <Container>
        <ul className="bread-crumbs__list">
          <li className="bread-crumbs__item">
            <AppLink to="/" className="bread-crumbs__item-text">
              {t('home')}
            </AppLink>
          </li>

          {dataList.map((item: IBreadCrumbs, index: number) => (
            <li key={item.id + index} className="bread-crumbs__item">
              {index === dataList.length - 1 ? (
                <span className="bread-crumbs__item-text">{item.name}</span>
              ) : (
                <AppLink
                  key={item.id + index}
                  to={`${item.link}`}
                  className="bread-crumbs__item-text"
                >
                  {item.name}
                </AppLink>
              )}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}

export default BreadCrumbs
