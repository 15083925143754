import React, {FC, useEffect, useState} from 'react'
import {BaseSvg, Container, Map} from '../../index'
import './ClubTop.scss'
import '../../../assets/scss/_utils.scss'
import {useTranslation} from 'react-i18next'
import {IMarkers} from '../../../models/Markers'

interface IProps {
    address: string
    slug: string
    lat: number
    lng: number
    name: string
    phone: string[]
    scheduler: string
    isLinkToMaps?: boolean
    gMapsUrl?: string
}

const ClubTop: FC<IProps> = (props) => {
    const {t} = useTranslation()
    const [map, setMap] = useState<IMarkers[] | null>(null)
    const schedulerArr = props.scheduler?.split('|') || []

    useEffect(() => {
        if (!props.address) return
        setMap([
            {
                id: 1,
                title: props.name,
                address: props.address,
                slug: props.slug,
                position: {
                    lat: props.lat,
                    lng: props.lng,
                },
            },
        ])
    }, [props.address])

    return (
        <div className="club-top">
            <Container>
                <div className="club-top__body">
                    {map && (
                        <Map
                            hideInfoWindow={true}
                            markers={map}
                            gMapsUrl={props.gMapsUrl}
                            className="club-top__map d-md-none"
                        />
                    )}
                    <div className="club-top__items">
                        <div className="club-top__item">
                            <div className="club-top__item-svg">
                                <BaseSvg name="ico-location"/>
                            </div>
                            <div className="club-top__item-info">
                                <div className="club-top__item-title">{t('club_address')}</div>
                                <div className="club-top__item-subtitle">{props.address}</div>

                                {props.isLinkToMaps && (
                                    <a
                                        href={`https://www.google.com/maps/dir//${props.lat},${props.lng}`}
                                        target="_blank"
                                        className="club-top__link-maps d-none d-md-block"
                                    >
                                        клуб на карте
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="club-top__item">
                            <div className="club-top__item-svg">
                                <BaseSvg name="ico-clock"/>
                            </div>
                            <div className="club-top__item-info">
                                <div className="club-top__item-title">{t('working_mode')}</div>

                                {schedulerArr.map((item: string, index: number) => (
                                    <div key={index} className="club-top__item-subtitle">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="club-top__item">
                            <div className="club-top__item-svg">
                                <BaseSvg name="ico-tel"/>
                            </div>
                            <div className="club-top__item-info">
                                <div className="club-top__item-title">{t('phones')}</div>
                                {props.phone.map((item: string, index: number) => (
                                    <a
                                        key={index}
                                        className="club-top__item-subtitle"
                                        href={`tel:${item}`}
                                    >
                                        {item}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    {map && (
                        <Map
                            hideInfoWindow={true}
                            markers={map}
                            gMapsUrl={props.gMapsUrl}
                            className="club-top__map d-none d-md-block border"
                        />
                    )}
                </div>
            </Container>
        </div>
    )
}

ClubTop.defaultProps = {
    isLinkToMaps: false,
}
export default ClubTop
