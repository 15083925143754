import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Promo from '../../components/Promo/Promo'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import './Blog.scss'
import { BaseBorderMobile, BaseSvg, BlogItems } from '../../components'
import TickerReact from '../../components/TickerReact/TickerReact'
import ReactPaginate from 'react-paginate'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'
import { fetchBlogs } from '../../store/slices/blogs'
import useScrollTo from '../../hooks/scrollTo'
import { fetchTags } from '../../store/slices/tags'
import { ITags } from '../../models/Tags'
import AppLink from '../../components/Base/AppLink'
import { useTranslation } from 'react-i18next'

const Blog: FC = () => {
  const { t } = useTranslation()
  const { scrollTo } = useScrollTo()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { data, pagination } = useAppSelector((state: RootState) => state.blogs)
  const { tags } = useAppSelector((state: RootState) => state.tags)
  const { isGlobalPending } = useAppSelector(
    (state: RootState) => state.loading
  )

  const [isActiveTag, setIsActiveTag] = useState<null | number>(null)

  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('blog'),
      link: '/information',
    },
  ])

  useEffect(() => {
    const tagQuery = searchParams.get('tag')

    if (tagQuery && tags.length) {
      const tag = tags.find((el: ITags) => el.slug === tagQuery)
      if (!tag) return
      setBreadCrumbs((prevState: IBreadCrumbs[]) => [
        prevState[0],
        { id: tag.id, name: tag.name },
      ])
      setIsActiveTag(tag.id)
    } else if (breadCrumbs.length > 1) {
      setBreadCrumbs((prevState: IBreadCrumbs[]) => [prevState[0]])
      setIsActiveTag(null)
    }
  }, [searchParams, tags])

  useEffect(() => {
    const page = Number(searchParams.get('page')) || 1
    const tag = searchParams.get('tag') || ''
    dispatch(fetchBlogs({ page, tag }))
  }, [searchParams])

  useEffect(() => {
    dispatch(fetchTags())
  }, [])

  const handlePageClick = (e: { selected: number }) => {
    scrollTo(e.selected + 1)
  }

  const [showFilter, setShowFilter] = useState(false)
  return (
    <>
      <Helmet>
        <title>{t('information_pulse_gym')}</title>
        <meta
          name="description"
          content={`${t('information_network_of_gyms')}`}
        />
      </Helmet>
      <div className="blog-page">
        <Promo title={t('information')}>
          <button
            className="blog-page__filter d-none d-md-flex"
            onClick={() => setShowFilter(!showFilter)}
          >
            {t('filter')}
            <BaseSvg name="ico-filter" />
          </button>
          <div className={`blog-page__wrapper ${showFilter ? 'show' : ''}`}>
            <div className="blog-page__wrapper-buttons">
              <div className="blog-page__buttons">
                {tags && !isGlobalPending && tags.length
                  ? tags.map((item: ITags) => (
                      <AppLink
                        to={`/information?tag=${item.slug}`}
                        key={item.id}
                        onClick={() => setShowFilter(false)}
                        className={`blog-page__btn ${
                          item.id === isActiveTag ? 'active' : ''
                        }`}
                      >
                        #
                        {typeof item.name === 'string'
                          ? item.name
                          : 'not string'}
                      </AppLink>
                    ))
                  : ''}
                <button
                  className="blog-page__btn-close d-none d-md-flex"
                  onClick={() => setShowFilter(false)}
                >
                  {t('close')}
                  <BaseSvg name="ico-close" />
                </button>
              </div>
            </div>
            <BaseBorderMobile />
          </div>
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        <BlogItems items={data} />
        {pagination && pagination?.last_page > 1 ? (
          <ReactPaginate
            breakLabel="..."
            pageCount={pagination?.last_page ?? 1}
            onPageChange={(e) => handlePageClick(e)}
            hrefBuilder={(page: number, pageCount: number) =>
              page >= 1 && page <= pageCount ? `/?page=${page}` : '#'
            }
            initialPage={
              searchParams.get('page')
                ? Number(searchParams.get('page')) - 1
                : 0
            }
            pageRangeDisplayed={2}
            renderOnZeroPageCount={() => null}
            marginPagesDisplayed={1}
            containerClassName="paginate"
            activeClassName="_active"
            pageClassName="paginate__item"
            pageLinkClassName="paginate__link"
            breakClassName="paginate__break"
            previousClassName="paginate__previous"
            nextClassName="paginate__next"
          />
        ) : (
          ''
        )}

        <TickerReact className="blog-page__ticker d-md-none" />
      </div>
    </>
  )
}

export default Blog
