import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from '../../api'
import {IClubs} from '../../models/Clubs'
import {IPolygon} from '../../models/Polygon'

export const fetchClubs = createAsyncThunk<IClubs[], void>(
    'clubs/fetchClubs:load',
    async function () {
        const response = await api.get('clubs')
        return response.data
    }
)

interface ClubsState {
    clubs: IClubs[]
    polygon: IPolygon[] | null
}

const initialState: ClubsState = {
    clubs: [],
    polygon: null,
}

const clubsSlice = createSlice({
    name: 'clubs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClubs.fulfilled, (state, action) => {
            // @ts-ignore
            state.clubs = action.payload.clubs
            // @ts-ignore
            state.polygon = action.payload.polygon
        })
    },
})

export const clubsReducers = clubsSlice.reducer
