import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import Modal from 'react-modal'
import './assets/scss/index.scss'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store'

import './i18n'

Modal.setAppElement('#root')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
)
