import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { languages } from '../../settings/languages'

interface IProps {
  to: string
  children?: ReactNode
  isNavLink?: boolean
  className?: string
  onClick?: () => void
}

const AppLink = forwardRef((props: IProps, ref: ForwardedRef<any>) => {
  const { i18n } = useTranslation()

  if (props.isNavLink) {
    return (
      <NavLink
        ref={ref}
        to={
          i18n.language === languages.defaultLanguageCode
            ? props.to
            : `/${i18n.language}${props.to}`
        }
        onClick={props.onClick}
        className={props.className}
      >
        {props.children}
      </NavLink>
    )
  }

  return (
    <Link
      ref={ref}
      to={
        i18n.language === languages.defaultLanguageCode
          ? props.to
          : `/${i18n.language}${props.to}`
      }
      onClick={props.onClick}
      className={props.className}
    >
      {props.children}
    </Link>
  )
})

AppLink.defaultProps = {
  isNavLink: false,
  className: '',
}

export default AppLink
