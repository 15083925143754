import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import LayoutDefault from './layouts/Default'
import {
  Blog,
  Club,
  Clubs,
  Contacts,
  Guide,
  Home,
  OneBlog,
  Photo,
  Reviews,
  Trainer,
} from './pages'
import PhotoGallery from './pages/PhotoGallery'
import ClubPrice from './pages/ClubPrice/ClubPrice'
import ClubSchedulePage from './pages/ClubSchedulePage/ClubSchedulePage'
import Error from './pages/Error/Error'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Regulations from './pages/Regulations/Regulations'
import { languages } from './settings/languages'
import RehabilitationCenter from './pages/RehabilitationCenter/RehabilitationCenter'

const App = () => {
  const { i18n, t } = useTranslation()

  const handleHideMainPreloader = () => {
    const mainPreloader = document.querySelector<HTMLElement>('.preloader-page')

    if (mainPreloader) {
      setTimeout(() => {
        mainPreloader.style.opacity = '0'
        mainPreloader.style.display = 'none'
      }, 300)
    }
  }

  useEffect(() => {
    handleHideMainPreloader()
  }, [])

  return (
    <BrowserRouter>
      <Helmet>
        <title>{t('network_of_Kharkiv_sports_clubs')}</title>
        <meta
          name="description"
          content={`${t('network_of_gyms_pulse_gym')}`}
        />
      </Helmet>
      <ScrollToTop>
        <Routes key={i18n.language}>
          {i18n.language !== languages.defaultLanguageCode &&
          !window.location.pathname.includes(i18n.language) ? (
            window.location.pathname === '/' ? (
              <Route
                index
                element={
                  <Navigate
                    replace
                    to={`/${i18n.language}${window.location.pathname}`}
                  />
                }
              />
            ) : (
              <Route
                path={window.location.pathname}
                element={
                  <Navigate
                    replace
                    to={`/${i18n.language}${window.location.pathname}`}
                  />
                }
              />
            )
          ) : null}

          {i18n.languages.map((lang, index) => (
            <React.Fragment key={`${index}_${i18n.language}`}>
              <Route
                path={lang === languages.defaultLanguageCode ? '/' : `/${lang}`}
                element={<LayoutDefault />}
              >
                <Route index element={<Home />} />
                {lang === 'ru' && <Route path="glavnaya" element={<Home />} />}
                <Route path="clubs" element={<Clubs />} />
                <Route path="club/:id" element={<Club />} />
                <Route path="clubs-price/:id" element={<ClubPrice />} />
                <Route
                  path="clubs-schedule/:id"
                  element={<ClubSchedulePage />}
                />
                <Route path="trainer/:id/:slug" element={<Trainer />} />
                <Route path="information" element={<Blog />} />
                <Route path="reviews" element={<Reviews />} />
                <Route path="contact" element={<Contacts />} />
                <Route path="gallery" element={<Photo />} />
                <Route path="gallery/:id" element={<PhotoGallery />} />
                <Route path="regulations/:id" element={<Regulations />} />
                <Route
                  path="reabilitacionnyj-centr"
                  element={<RehabilitationCenter />}
                />
                <Route
                  path="reabilitaciynyi-centr-saltivka"
                  element={<RehabilitationCenter />}
                />
                <Route path=":id" element={<OneBlog />} />
                <Route path="*" element={<Error />} />
                {process.env.NODE_ENV === 'development' && (
                  <Route path="guide" element={<Guide />} />
                )}
              </Route>
            </React.Fragment>
          ))}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App
