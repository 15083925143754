import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Modal, {Styles} from 'react-modal'
import Promo from '../../components/Promo/Promo'
import { BaseSvg, Container } from '../../components'
import { useParams } from 'react-router-dom'
import '../../assets/scss/_utils.scss'
import './PhotoGallery.scss'
import TickerReact from '../../components/TickerReact/TickerReact'
import windowDimensions from '../../hooks/windowDimensions'
import Gallery from '../../components/Gallery/Gallery'
import { fetchClub } from '../../store/slices/club'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'
import { IMedia } from '../../models/Clubs'
import AppLink from '../../components/Base/AppLink'
import { useTranslation } from 'react-i18next'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'

const overlayStyles: React.CSSProperties = {
    backgroundColor: 'rgba(7,7,19,0.25)',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}

const contentStyles: React.CSSProperties = {
    padding: 0,
    position: undefined,
    border: 'none',
    color: 'inherit',
    backgroundColor: 'transparent',
    borderRadius: 0,
    overflow: 'visible',
}

const basicStyleModal: Styles = {
  overlay: overlayStyles,
  content: contentStyles,
}

const PhotoGallery: FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { club } = useAppSelector((state: RootState) => state.club)
  const { isGlobalPending } = useAppSelector(
    (state: RootState) => state.loading
  )
  const [modalIsOpen, setIsOpen] = useState(false)
  const { windowWidth, md } = windowDimensions()
  const [activeSlide, setActiveSlide] = useState<null | number>(null)

  const [breadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('photo_gallery'),
    },
  ])

  function closeModal() {
    setIsOpen(false)
    setActiveSlide(null)
  }

  function openModal(id: number) {
    if (windowWidth < md) return
    setIsOpen(true)
    setActiveSlide(id)
  }

  useEffect(() => {
    dispatch(fetchClub(id))
  }, [])

  return (
    <>
      <Helmet>
        {club?.seo?.title && <title>{club.seo.title}</title>}
        {club?.seo?.description && (
          <meta name="description" content={`${club?.seo?.description}`} />
        )}
      </Helmet>
      <div className="photo-gallery">
        <Promo
          disabledBorder={windowWidth > md}
          title={club ? `PULSE GYM ${club.name}` : ''}
        >
          <AppLink
            to="/gallery/"
            className="photo-gallery__promo btn _strong-italic d-md-none"
          >
            <BaseSvg name="ico-arrow-right-long" />
            <span>{t('all_clubs')}</span>
          </AppLink>
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        <Container>
          <ul className="photo-gallery__list">
            {club?.media &&
              !isGlobalPending &&
              club.media.map((item: IMedia, index: number) => (
                <li
                  key={index}
                  onClick={() => openModal(item.id)}
                  className="photo-gallery__item"
                >
                  <button type="button" className="photo-gallery__item-button">
                    <picture className="photo-gallery__item-picture">
                      <img
                        src={item.original_url}
                        alt="img"
                        className="photo-gallery__item-img"
                      />
                    </picture>
                  </button>
                </li>
              ))}
          </ul>
        </Container>
        <TickerReact className="_mb-2" />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Write a feedback"
          closeTimeoutMS={200}
          preventScroll={true}
          style={basicStyleModal}
          bodyOpenClassName="_scroll-lock"
          portalClassName="modal-portal"
        >
          {club?.media && (
            <Gallery images={club.media} activeSlide={activeSlide} />
          )}
        </Modal>
      </div>
    </>
  )
}
export default PhotoGallery
