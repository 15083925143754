import React, { FC, useEffect, useRef, useState } from 'react'
import {
  BaseSliderPagination,
  BaseSvg,
  ButtonArrowRight,
  Container,
} from '../../index'
import './ClubTrainers.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, EffectFade, Controller } from 'swiper'
import ClubTrainersItem from './ClubTrainersItem'
import { ICoaches } from '../../../models/Clubs'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import pug_img from '../../../assets/image/pug.jpg'

interface IProps {
  coaches: ICoaches[]
}

SwiperCore.use([Navigation, Controller, EffectFade])

const ClubTrainers: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [pug, setPug] = useState<number>(0)
  const imageSwiperRef = useRef<any>(null)
  const textSwiperRef = useRef<any>(null)

  useEffect(() => {
    if (imageSwiperRef && textSwiperRef) {
      const imageSwiper = imageSwiperRef.current.swiper
      const textSwiper = textSwiperRef.current.swiper
      imageSwiper.controller.control = textSwiper
      textSwiper.controller.control = imageSwiper
    }
  }, [])

  useEffect(() => {
    if (props.coaches.length < 4) {
      setPug(4 - props.coaches.length)
    }
    return () => {
      setPug(0)
    }
  }, [props.coaches.length])

  return (
    <div className={`club-trainers ${!props.coaches.length ? 'd-none' : ''}`}>
      <Container>
        <div className="club-trainers__body">
          <div className="club-trainers__title d-md-none">
            <h2 className="title-club">{t('our_trainers')}</h2>
            <div className="club-trainers__buttons">
              <button className="club-trainers__btn club-trainers__btn-prev">
                <BaseSvg name="ico-arrow-right" />
              </button>
              <button className="club-trainers__btn club-trainers__btn-next">
                <BaseSvg name="ico-arrow-right" />
              </button>
            </div>
          </div>
          <Swiper
            ref={imageSwiperRef}
            slidesPerView={'auto'}
            navigation={{
              nextEl: '.club-trainers__btn-next',
              prevEl: '.club-trainers__btn-prev',
            }}
            pagination={{
              el: '.club-trainers__pagination',
              clickable: true,
            }}
            className="club-trainers__slider"
          >
            {props.coaches.map((item, index) => (
              <SwiperSlide key={index} className="club-trainers__slide">
                <ClubTrainersItem item={item} />
              </SwiperSlide>
            ))}
            {pug
              ? Array.from(Array(pug)).map((key, index) => (
                  <SwiperSlide
                    key={index}
                    className="club-trainers__slide d-md-none"
                  >
                    <picture className=" club-trainers__pug">
                      <img
                        src={pug_img}
                        alt=""
                        className="club-trainers__pug-img"
                      />
                    </picture>
                  </SwiperSlide>
                ))
              : null}

            <BaseSliderPagination className="club-trainers__pagination d-none d-md-flex" />
          </Swiper>
          <div className="club-trainers__wrapper d-none d-md-block">
            <div className="club-trainers__title">{t('our_trainers')}</div>
            <Swiper
              // @ts-ignore
              ref={textSwiperRef}
              slidesPerView={'auto'}
              effect={'fade'}
              className="club-trainers__slider-sync"
            >
              {props.coaches.map((item: ICoaches) => (
                <SwiperSlide
                  className="club-trainers__slider-sync-slide"
                  key={item.id}
                >
                  <div className="club-trainers__slider-sync-name">
                    {item.name}
                  </div>
                  <div className="club-trainers__slider-sync-text">
                    {item.description}
                  </div>
                  <ButtonArrowRight to={`/trainer/${id}/${item.slug}/`}>
                    {t('learn_more_2')}
                  </ButtonArrowRight>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ClubTrainers
