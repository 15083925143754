import React, {FC, useEffect} from 'react'
import {BaseSvg, ButtonArrowRight, ButtonSlider, Container} from '../../index'
import './Clubs.scss'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation} from 'swiper'
import {IClubs} from '../../../models/Clubs'
import AppLink from '../../Base/AppLink'
import {useTranslation} from 'react-i18next'
import Badge from '../../Badge/Badge'
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {RootState} from "../../../store";
import {fetchPromo} from "../../../store/slices/promo";
import {fetchClubInfo} from "../../../store/slices/club-info";

interface IProps {
    clubs: IClubs[]
}

SwiperCore.use([Navigation])

const Clubs: FC<IProps> = (props) => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const {clubInfo} = useAppSelector((state: RootState) => state.clubInfo)

    useEffect(() => {
        if (clubInfo) return
        dispatch(fetchClubInfo())
    }, [])

    return (
        <section className="home-clubs">
            <Container>
                <div className="home-clubs__body">
                    <div className="home-clubs__info">
                        <h2 className="home-clubs__title">
                            {clubInfo?.title ? clubInfo?.title : t('already_20_gyms_in_Kharkiv')}
                        </h2>
                        <p className="home-clubs__subtitle">
                            {clubInfo?.sub_title ? clubInfo?.sub_title : t('we_make_the_city_healthier_and_people_more_successful')}
                        </p>
                        <ButtonArrowRight isModMobile to="/clubs/">
                            {t('see_all')}
                        </ButtonArrowRight>
                    </div>
                    <Swiper
                        className="home-clubs__slider"
                        slidesPerView="auto"
                        navigation={{
                            nextEl: '.home-clubs__btn._next',
                            prevEl: '.home-clubs__btn._prev',
                        }}
                    >
                        {props.clubs.map((item: IClubs) => (
                            <SwiperSlide className="home-clubs__slide" key={item.id}>
                                <AppLink
                                    to={`/club/${item.slug}/`}
                                    className="home-clubs__slide-link"
                                >
                                    <BaseSvg
                                        name="ico-arrow-right-short"
                                        className="home-clubs__slide-arrow  d-md-none"
                                    />
                                    <img
                                        className="home-clubs__slide-img"
                                        src={item.image}
                                        alt="img"
                                    />
                                    <Badge
                                        new={item.new}
                                        not_working={item.not_working}
                                        created_at={item.created_at}
                                        className="home-clubs__slide-new"
                                    />
                                    <div className="home-clubs__slide-content">
                                        <BaseSvg
                                            className="home-clubs__slide-logo"
                                            name="ico-logo-text"
                                        />
                                        <div className="home-clubs__slide-title">{item.name}</div>
                                    </div>
                                </AppLink>
                            </SwiperSlide>
                        ))}
                        <ButtonSlider type="prev" className="home-clubs__btn "/>
                        <ButtonSlider type="next" className="home-clubs__btn"/>
                    </Swiper>
                </div>
            </Container>
        </section>
    )
}

export default Clubs
