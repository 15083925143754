import React, { FC } from 'react'
import './TrainerList.scss'
import { ISpecialization } from '../../models/Trainer'
import { useTranslation } from 'react-i18next'

interface IProps {
  items: ISpecialization[]
}

const TrainerList: FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <div className="trainer-list">
      {props?.items.length ? (
        <div className="trainer-list__title">{t('specialization')}</div>
      ) : null}
      <ul className="trainer-list__items">
        {props?.items.map((item: ISpecialization, index) => (
          <li key={index} className="trainer-list__item">
            <span />
            {item?.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TrainerList
