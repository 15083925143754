import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IAskFeedback } from '../../models/Feedback'
import { AxiosError } from 'axios'


export const sendFeedback = createAsyncThunk(
  'feedback/sendFeedback:load',
  async function (payload: any, { rejectWithValue }) {
    try {
      const params = {
        ...payload,
      }
      const response = await api.post('ask-feedback', params)

      return response.data
    } catch (err) {
      const error = err as AxiosError
      return rejectWithValue(error?.response?.data)
    }
  }
)

interface IFeedbackState {
  data: IAskFeedback[]
  errors: any
}

const initialState = {
  data: [],
  errors: null,
} as IFeedbackState

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendFeedback.rejected, (state, action: any) => {
        state.errors = action.payload?.errors
      })
  },
})

export const askFeedbackReducers = feedbackSlice.reducer
export const { clearErrors } = feedbackSlice.actions
