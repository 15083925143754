import React, { FC } from 'react'
import './TrainerSocial.scss'
import { BaseSvg } from '../index'
import { ISocial } from '../../models/Trainer'

interface IProps {
  className?: string
  phone: string | null
  social: ISocial[] | null
}

const TrainerSocial: FC<IProps> = (props) => {
  return (
    <div className={`trainer-social ${props.className ? props.className : ''}`}>
      <div className="trainer-social__left">
        {props.social && props.social.length
          ? props?.social.map((item: ISocial, index: number) => (
              <a key={index} href={item.url} className="trainer-social__link">
                <BaseSvg name={`ico-${item.icon}`} />
              </a>
            ))
          : null}

        {props?.phone && (
          <a
            href={`tel:${props?.phone}`}
            className="trainer-social__link _phone"
          >
            <BaseSvg name="ico-tel" />
          </a>
        )}
      </div>
      {props?.phone && (
        <div className="trainer-social__right">
          <a href={`tel:${props?.phone}`} className="trainer-social__phone">
            {props?.phone}
          </a>
        </div>
      )}
    </div>
  )
}

export default TrainerSocial
