import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { ISmartFit } from '../../models/SmartFit'

export const fetchSmartFit = createAsyncThunk(
  'feedback/fetchSmartFit:load',
  async function () {
    const response = await api.get('smartfit')
    return response.data
  }
)

interface ISmartFitState {
  smartFit: ISmartFit | null
}

const initialState = {
  smartFit: null,
} as ISmartFitState

const smartFitSlice = createSlice({
  name: 'smartFit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSmartFit.fulfilled, (state, action) => {
      state.smartFit = action.payload.data
    })
  },
})

export const smartFitReducers = smartFitSlice.reducer
