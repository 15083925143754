import React, { FC } from 'react'
import './PaginationFraction.scss'

interface IProps {
  className?: string
}

const PaginationFraction: FC<IProps> = (props) => {
  return <div className={`pagination-fraction ${props.className}`}></div>
}

PaginationFraction.defaultProps = {
  className: '',
}

export default PaginationFraction
