import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IPromo } from '../../models/Promo'
import {IClubInfo} from "../../models/ClubInfo";

export const fetchClubInfo = createAsyncThunk(
  'clubInfo/fetchClubInfo:load',
  async function () {
    const response = await api.get(`/club-info`)
    return response.data
  }
)

interface IClubInfoState {
  clubInfo: IClubInfo | null
}

const initialState = {
  clubInfo: null,
} as IClubInfoState

const clubInfoSlice = createSlice({
  name: 'clubInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClubInfo.fulfilled, (state, action) => {
      state.clubInfo = action.payload.data as IClubInfo
    })
  },
})

export const clubInfoReducers = clubInfoSlice.reducer
