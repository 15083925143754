import React, { FC } from 'react'
import { BlogInner, Container } from '../index'
import './BlogItems.scss'
import { IBlogs } from '../../models/Blogs'

interface IProps {
  items: IBlogs[]
}

const BlogItems: FC<IProps> = (props) => {
  return (
    <div className="blog-items">
      <Container>
        {props.items && props.items.length ? (
          <div className="blog-items__body">
            {props.items.map((item: IBlogs) => (
              <BlogInner
                key={item.id}
                id={item.slug || null}
                image={item.image}
                tags={item.tags}
                title={item.title}
                date={item.date}
              />
            ))}
          </div>
        ) : (
          ''
        )}
      </Container>
    </div>
  )
}

export default BlogItems
