import React, {FC, useRef, useState} from 'react'
import {BaseSvg} from '../../index'
import 'moment/locale/ru'
import './ClubMobileList.scss'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import ClubPriceBanner from '../ClubPrice/ClubPriceBanner'
import ClubPriceCoachServices from '../ClubPrice/ClubPriceCoachServices'
import {DaysOfWeekEnum, DaysOfWeekNumsEnum, IBaner, IScheduleItem} from '../../../models/Clubs'

interface IProps {
    title?: string
    schedule?: IScheduleItem
    price?: any
    baner?: IBaner[]
}

moment.locale('ru')

const ClubMobileList: FC<IProps> = ({title, schedule, price, baner}) => {
    const {t} = useTranslation()
    const listEl = useRef<any>()
    const wrapperEl = useRef<any>()

    const [show, setShow] = useState(false)
    const showList = () => {
        if (!show) {
            wrapperEl.current.style.height = listEl.current.offsetHeight + 1 + 'px'
            setShow(true)
        } else {
            wrapperEl.current.style.height = 0
            setShow(false)
        }
    }

    return (
        <div className={`mobile-list ${show ? '_show' : ''}`}>
            <button className="mobile-list__top" onClick={() => showList()}>
        <span className="mobile-list__top-wrapper">
          {title}
            <BaseSvg name="ico-arrow-bottom"/>
        </span>
                {schedule && schedule.recruiting ? (
                        <span className="mobile-list__top-recruiting">
                          {t('enrollment_for_classes')}
                        </span>
                    ) : null
                }
            </button>
            <div ref={wrapperEl} className="mobile-list__wrapper">
                <ul ref={listEl} className="mobile-list__list">
                    {schedule
                        ? Object.keys(schedule).filter(value => {
                            return value in DaysOfWeekEnum
                        }).map((item, index) => (
                            <li
                                key={index}
                                className={`mobile-list__item ${
                                    schedule[item].length !== 0 ? '' : 'd-none'
                                }`}
                            >
                              <span className="mobile-list__item-text">
                                {moment.weekdays(DaysOfWeekNumsEnum[item])} {item}
                              </span>
                                {schedule[item].map((el, index) => (
                                    <span key={index} className="mobile-list__item-price _time">
                                      {el && moment(el, 'HH:mm:ss').format('HH:mm')}
                                    </span>
                                ))}
                            </li>
                        ))
                        : null}
                    {price &&
                        price.map((item) => (
                            <li key={item.id} className="mobile-list__item">
                                <div className="mobile-list__item-inner">
                                    <span className="mobile-list__item-text">{item.name}</span>
                                    <span className="mobile-list__item-price">{item.price}</span>
                                </div>
                            </li>
                        ))}
                    {baner?.length ? (
                        <li className="mobile-list__baner">
                            {baner.map((item) => {
                                if (item.type === 'two-free-training') {
                                    return (
                                        <ClubPriceBanner
                                            key={item.type}
                                            title={item.title}
                                            sub_title={item.sub_title}
                                            mobile_title={item.mobile_title}
                                            mobile_sub_title={item.mobile_sub_title}
                                        />
                                    )
                                } else if (item.type === 'freeze') {
                                    return (
                                        <ClubPriceCoachServices
                                            key={item.type}
                                            title={item.title}
                                            sub_title={item.sub_title}
                                            mobile_title={item.mobile_title}
                                            mobile_sub_title={item.mobile_sub_title}
                                        />
                                    )
                                } else if (item.type === 'personal-coach') {
                                    return (
                                        <ClubPriceCoachServices
                                            key={item.type}
                                            slug={item.slug}
                                            title={item.title}
                                            sub_title={item.sub_title}
                                            mobile_title={item.mobile_title}
                                            mobile_sub_title={item.mobile_sub_title}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    )
}

export default ClubMobileList
