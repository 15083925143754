import React, { FC } from 'react'
import { BaseSvg, ButtonArrowRight } from '../../index'
import './ClubTrainersItem.scss'
import { useParams } from 'react-router-dom'
import { ICoaches } from '../../../models/Clubs'
import AppLink from '../../Base/AppLink'
import { useTranslation } from 'react-i18next'

interface IProps {
  item: ICoaches
}

const ClubTrainersItem: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <div className="trainers-item">
      <AppLink
        to={`/trainer/${id}/${props.item.slug}/`}
        className=" trainers-item__top"
      >
        <img src={props.item.image} alt={props.item.name} />
        <div className=" trainers-item__content d-md-none">
          <BaseSvg name="ico-logo-text" className=" trainers-item__svg" />
          <div className=" trainers-item__title">{props.item.name}</div>
        </div>
      </AppLink>
      <div className=" trainers-item__bottom d-md-none">
        <div className=" trainers-item__text">{props.item.description}</div>
        <ButtonArrowRight
          to={`/trainer/${id}/${props.item.slug}`}
          className=" trainers-item__bottom-link"
        >
          {t('learn_more_2')}
        </ButtonArrowRight>
      </div>
    </div>
  )
}

export default ClubTrainersItem
