import React, { FC, useState, memo } from 'react'
import { BaseSvg, ButtonArrowRight, ButtonSlider } from '../../index'
import Modal from 'react-modal'
import './Reviews.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import { IFeedback } from '../../../models/Feedback'
import WriteFeedback from '../../WriteFeedback/WriteFeedback'
import useBasicStyleModal from '../../../helpers/getBasicStyleModal'
import { useTranslation } from 'react-i18next'

SwiperCore.use([Navigation])

interface IProps {
  feedback: IFeedback[]
}

const Reviews: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { basicStyleModal } = useBasicStyleModal()
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <section className="reviews-home">
      <div className="reviews-home__body">
        <BaseSvg className="reviews-home__quotes" name="ico-quotes" />
        <Swiper
          className="reviews-home__slider"
          navigation={{
            nextEl: '.reviews-home__btn._next',
            prevEl: '.reviews-home__btn._prev',
          }}
        >
          {props?.feedback?.length
            ? props.feedback.map((item) => (
                <SwiperSlide key={item.id} className="reviews-home__slide">
                  <div className="reviews-home__slide-top">
                    <span>{item.name},</span>
                    {item?.club?.address || ''}
                  </div>
                  <div className="reviews-home__slide-text">
                    {item.feedback}
                  </div>
                </SwiperSlide>
              ))
            : null}
        </Swiper>
        <div className="reviews-home__buttons">
          <ButtonSlider
            isHover
            type="prev"
            className="reviews-home__btn _prev _gray _small"
          />
          <button
            type="button"
            onClick={openModal}
            className="reviews-home__buttons-modal d-md-none"
          >
            {t('give_feedback')}
          </button>
          <ButtonSlider
            isHover
            type="next"
            className="reviews-home__btn _next _gray _small"
          />
        </div>
        <ButtonArrowRight
          isModMobile
          onClick={openModal}
          className="reviews-home__mobile-btn d-none d-md-flex"
        >
          оставить свой отзыв
        </ButtonArrowRight>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Write a feedback"
        closeTimeoutMS={200}
        preventScroll={true}
        style={basicStyleModal}
        bodyOpenClassName="_scroll-lock"
        portalClassName="modal-portal"
      >
        <WriteFeedback close={closeModal} />
      </Modal>
    </section>
  )
}

export default memo(Reviews)
