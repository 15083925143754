import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from '../../api'
import {ISetting} from '../../models/Settings'

export const fetchSettings = createAsyncThunk<ISetting[], void>(
    'settings/fetchSettings:load',
    async function () {
        const response = await api.get('settings')
        return response.data
    }
)

interface SettingsState {
    settings: ISetting[]
}

const initialState: SettingsState = {
    settings: [],
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            state.settings = action.payload
        })
    },
})

export const settingsReducer = settingsSlice.reducer
