import React, { FC, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import './Gallery.scss'
import { BaseSvg } from '../index'
import SwiperCore, { EffectFade, Navigation } from 'swiper'
import { IMedia } from '../../models/Clubs'

SwiperCore.use([Navigation, EffectFade])

interface IProps {
  images: IMedia[]
  activeSlide: number | null
}

const Gallery: FC<IProps> = (props) => {
  const [swiper, setSwiper] = useState<any>()
  const galleryCarousel = useRef<any>()

  useEffect(() => {
    if (swiper && props.activeSlide) {
      swiper.activeIndex = props.images.findIndex(
        (el) => el.id === props.activeSlide
      )
    }
  }, [swiper])

  return (
    <div className="gallery">
      <div className="gallery__inner">
        <Swiper
          onSwiper={setSwiper}
          slidesPerView="auto"
          navigation={{
            nextEl: '.gallery__navigation-item._next',
            prevEl: '.gallery__navigation-item._prev',
          }}
          ref={galleryCarousel}
          className="gallery__carousel"
        >
          {props.images.map((el: IMedia) => (
            <SwiperSlide key={el.id} className="gallery__carousel-item">
              <picture className="gallery__carousel-picture">
                <img
                  src={el.original_url}
                  alt="img"
                  className="gallery__carousel-img"
                />
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="gallery__navigation d-md-none">
          <button type="button" className="gallery__navigation-item _prev">
            <BaseSvg
              name="ico-arrow-slider"
              className="gallery__navigation-svg"
            />
          </button>
          <button type="button" className="gallery__navigation-item _next">
            <BaseSvg
              name="ico-arrow-slider"
              className="gallery__navigation-svg"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Gallery
