import React, { useEffect, useState } from 'react'
import {
  BaseSvg,
  Button,
  ButtonArrowRight,
  ButtonSlider,
  Container,
  Map,
} from '../../components'
import './Guide.scss'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const markers = [
  {
    id: 1,
    title: 'НА ПОБЕДЕ',
    address: `${Math.random()} Schowalter`,
    slug: '1',
    position: {
      lat: 50.73712890416463,
      lng: 25.3665426457313,
    },
  },
  {
    id: 2,
    title: 'Імедж',
    address: `${Math.random()} Schowalter`,
    slug: '1',
    position: {
      lat: 50.7444300688927,
      lng: 25.37872584733814,
    },
  },
  {
    id: 3,
    title: 'Промінь',
    address: `${Math.random()} Schowalter `,
    slug: '1',
    position: {
      lat: 50.75347887418115,
      lng: 25.33328959100226,
    },
  },
  {
    id: 4,
    title: 'WOG',
    address: `${Math.random()} Schowalter `,
    slug: '1',
    position: {
      lat: 50.744926154906004,
      lng: 25.270968873221694,
    },
  },
]

const Index = () => {
  const [svgListArr, setSvgListArr] = useState<string[]>([])

  const createSvgList = (): void => {
    const arr = Array.from(document.querySelectorAll('#svgList defs symbol'))
    if (!arr) return

    for (const item of arr) {
      setSvgListArr((current: string[]) => [...current, item.id])
    }
  }

  const notify = () => {
    toast.error('Wow so easy!', {
      closeButton: false,
    })
  }

  useEffect(() => {
    createSvgList()
  }, [])

  return (
    <div className="guide">
      <Container>
        <ul className="guide__list">
          <li className="guide__item">
            <div className="guide__h1">SVG</div>
            <div className="svg-list">
              {svgListArr &&
                svgListArr.length &&
                svgListArr.map((item: string, index: number) => (
                  <div key={`${item}_${index}`} className="svg-list__item">
                    <BaseSvg name={item} />
                    <span className="svg-list__label">{item}</span>
                  </div>
                ))}
            </div>
          </li>
          <li className="guide__item">
            <div className="guide__h1">BUTTON</div>
            <ul className="guide__buttons">
              <li className="guide__buttons-item">
                <button onClick={notify} type="button" className="btn">
                  button
                </button>
                <span className="guide__buttons-description">class: btn </span>
              </li>
              <li className="guide__buttons-item">
                <button type="button" className="btn _strong-italic">
                  button
                </button>
                <span className="guide__buttons-description">
                  class: btn _strong-italic
                </span>
              </li>
              <li className="guide__buttons-item">
                <button type="button" className="btn _gold-simple">
                  button
                </button>
                <span className="guide__buttons-description">
                  class: btn _gold-simple
                </span>
              </li>
              <li className="guide__buttons-item">
                <button type="button" className="btn _violet">
                  button
                </button>
                <span className="guide__buttons-description">
                  class: btn _violet
                </span>
              </li>
              <li className="guide__buttons-item">
                <Button>button</Button>
                <span className="guide__buttons-description">
                  component: Button
                </span>
              </li>
              <li className="guide__buttons-item">
                <ButtonArrowRight>button</ButtonArrowRight>
                <span className="guide__buttons-description">
                  component: ButtonArrowRight
                </span>
              </li>
              <li className="guide__buttons-item">
                <ButtonSlider type="prev" />
                <span className="guide__buttons-description">
                  component: ButtonArrowRight
                </span>
              </li>
            </ul>
          </li>
          <li className="guide__item">
            <Map markers={markers} />
          </li>
          <li className="guide__item"></li>
        </ul>
      </Container>
    </div>
  )
}

export default Index
