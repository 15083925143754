import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import api from '../api'
import moment from 'moment'
import 'moment/locale/uk'
import 'moment/locale/ru'
import {languages} from '../settings/languages'

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        debug: process.env.NODE_ENV !== 'production',
        supportedLngs: ['ua', 'ru'],
        fallbackLng: 'ua',
        detection: {
            order: [
                'path',
                'cookie',
                'htmlTag',
                'localStorage',
                'sessionStorage',
                'subdomain',
            ],
            caches: ['cookie'],
        },
        backend: {
            backendOptions: [{
                expirationTime: 30 * 60 * 1000 // 30 mins
            }, {
                loadPath: '/locales/{{lng}}/translation.json',
            }],
            loadPath: '/locales/{{lng}}/translation.json',
        },
    })
i18n.on('languageChanged', function (lng) {
    const currentLocale = languages?.languages.find((el) => el?.show_code === lng)

    if (currentLocale) {
        // document
        //   .querySelector('html')
        //   ?.setAttribute(
        //     'lang',
        //     `${currentLocale.locale}-${currentLocale.country_code}`
        //   )
        moment.locale(currentLocale.locale)
        api.defaults.headers.common['Accept-Language'] = currentLocale.locale
    }
})
export default i18n
