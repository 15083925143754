import React, {FC, memo, useCallback, useEffect, useState} from 'react'
import './Map.scss'
import marker from '../../assets/image/marker.svg'
import marker_active from '../../assets/image/marker_active.svg'
import {IMarkers} from '../../models/Markers'
import useBasicStyleModal from '../../helpers/getBasicStyleModal'
import windowDimensions from '../../hooks/windowDimensions'
import {IPolygon} from '../../models/Polygon'

interface IProps {
    markers: IMarkers[]
    className?: React.ReactNode
    hideInfoWindow?: boolean
    polygon?: IPolygon[],
    gMapsUrl?: string
}

const lineSymbol = {
    path: 'M 0,0 0,0',
    strokeOpacity: 1,
    scale: 2.5,
    strokeColor: 'red',
}

const options = {
    strokeOpacity: 0,
    fillOpacity: 0,
    zIndex: 1,
    icons: [
        {
            icon: lineSymbol,
            offset: '0',
            repeat: '5px',
        },
    ],
}

const Map: FC<IProps> = (props) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const {windowWidth, md} = windowDimensions()
    const [activeMarker, setActiveMarker] = useState<null | IMarkers>(null)
    const [modalIsOpen, setIsOpen] = useState(false)
    const {basicStyleModal} = useBasicStyleModal()
    const googleMapAPIKey = process.env.REACT_APP_GOOGLE_MAP_KEY
    const [mapQuery, setMapQuery] = useState("")

    useEffect(() => {
        let marker = props.markers[0]

        let query = [
            "Pulse Gym",
            marker.address
        ]

        query = query.map((item) => {
            item = item.replaceAll(" ", "+")

            return item
        })

        setMapQuery("&q=" + query.join(",") + "&center=" + marker.position.lat + "," + marker.position.lng + "&zoom=15")
    }, [props.markers])

    useEffect(() => {
        if (mapQuery.length > 1) {
            setIsLoaded(true)
        }
    }, [mapQuery])


    const handleActiveMarker = (marker: IMarkers): void => {
        if (props.hideInfoWindow) {
            window.open(
                `https://www.google.com/maps/dir//${marker.position.lat},${marker.position.lng}`,
                '_blank'
            )
        } else {
            if (activeMarker?.id === marker.id && windowWidth >= md) {
                setActiveMarker(null)
            } else {
                setActiveMarker(marker)

                if (windowWidth < md) {
                    setIsOpen(true)
                }
            }
        }
    }

    const getActiveMarker = (id: number) => {
        let markerIco = marker
        if (id === activeMarker?.id || props.hideInfoWindow) {
            markerIco = marker_active
        }

        return markerIco
    }

    const handleOnLoad = useCallback((map) => {
        const bounds = new google.maps.LatLngBounds()
        props.markers
            .filter((el) => el.position.lng && el.position.lat)
            .forEach(({position}) => bounds.extend(position))
        map.fitBounds(bounds)
    }, [])

    function closeModal() {
        setIsOpen(false)
        setActiveMarker(null)
    }

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension(){
        return {
            width: window.innerWidth >= 1024 ? 1024 : window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const renderMap = () => {
        return (
            <div className={`map ${props.className}`}>
                {props.gMapsUrl ? (
                    <iframe
                        width={screenSize.width}
                        height="350"
                        src={props.gMapsUrl}
                    >
                    </iframe>
                ) : (
                    <iframe
                        width="1024"
                        height="350"
                        style={{border: 0}}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={"https://www.google.com/maps/embed/v1/place?key=" + googleMapAPIKey + mapQuery}
                    >
                    </iframe>
                )}

                {/*<GoogleMap
          onLoad={handleOnLoad}
          options={{
            maxZoom: 14,
          }}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ width: '100%', height: '100%' }}
        >
          {props.markers &&
            props.markers.map((item) =>
              item.position.lat && item.position.lng ? (
                <Marker
                  key={item.id}
                  position={item.position}
                  animation={window.google.maps.Animation.DROP}
                  onClick={() => handleActiveMarker(item)}
                  icon={{
                    url: getActiveMarker(item.id),
                  }}
                >
                  {item.id === activeMarker?.id && windowWidth > md ? (
                    <InfoWindow
                      position={item.position}
                      options={{
                        pixelOffset: new window.google.maps.Size(0, 200),
                      }}
                      zIndex={99}
                    >
                      <AppLink to={`/club/${item.slug}/`} className="map__info">
                        <div className="map__info-head">
                          <div className="map__info-main">
                            <span className="map__info-name">Pulse Gym</span>
                            <span className="map__info-title">
                              {item.title}
                            </span>
                          </div>
                          <BaseSvg
                            name="ico-arrow-right-short"
                            className="map__info-svg"
                          />
                        </div>
                        <span className="map__info-address">
                          {item.address}
                        </span>
                      </AppLink>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ) : null
            )}
          {props.polygon && props.polygon.length && windowWidth > md ? (
            <Polyline
              options={{ ...options, path: props.polygon }}
              path={props.polygon}
            />
          ) : null}
        </GoogleMap>*/}
                {/*<Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Write a feedback"
                    closeTimeoutMS={200}
                    preventScroll={true}
                    style={basicStyleModal}
                    bodyOpenClassName="_scroll-lock"
                    portalClassName="modal-portal"
                >
                    <div className="modal map__modal">
                        <div className="modal__inner map__modal-inner">
                            {activeMarker && (
                                <AppLink
                                    to={`/club/${activeMarker?.slug || ''}/`}
                                    className="map__modal-wrap"
                                >
                                    <div className="map__modal-head">
                                        <div className="map__modal-content">
                                            <div className="map__modal-name">Pulse Gym</div>
                                            <div className="map__modal-title">
                                                {activeMarker.title}
                                            </div>
                                        </div>
                                        <BaseSvg
                                            name="ico-arrow-right-short"
                                            className="map__modal-svg"
                                        />
                                    </div>
                                    <div className="map__modal-street">
                                        {activeMarker.address}
                                    </div>
                                </AppLink>
                            )}
                        </div>
                    </div>
                </Modal>*/}
            </div>
        )
    }

    return isLoaded ? renderMap() : null
}

export default memo(Map)
