import React, { useEffect } from 'react'
import { BaseSvg, Container } from '../index'
import './HeaderMenu.scss'
import AppLink from '../Base/AppLink'
import { useTranslation } from 'react-i18next'
import ListLang from '../ListLang/ListLang'
import { useLocation } from 'react-router'
import welcome_gym_m from '../../assets/image/welcome_gym_m.jpg'

interface IProps {
  showMenu: boolean
  onHideMenu: () => void
}

const HeaderMenu = ({ showMenu, onHideMenu }: IProps) => {
  const { i18n, t } = useTranslation()
  const { pathname } = useLocation()

  const items = [
    {
      name: t('home'),
      link: '/',
    },
    {
      name: t('all_clubs'),
      link: '/clubs/',
    },
    {
      name: t('reviews'),
      link: '/reviews/',
    },
    {
      name: t('contacts'),
      link: '/contact/',
    },
    {
      name: t('blog'),
      link: '/information/',
    },
    {
      name: t('photo'),
      link: '/gallery/',
    },
  ]

  useEffect(() => {
    onHideMenu()
  }, [pathname])

  return (
    <div className={`header-menu d-none d-md-flex ${showMenu ? '_show' : ''}`}>
      <Container className="header-menu__inner">
        <div className="header">
          <div className="header__body">
            <AppLink
              to="/"
              className="header__logo"
              onClick={() => onHideMenu()}
            >
              <BaseSvg name="ico-logo" />
            </AppLink>
            <button
              className="header__burger d-none d-md-flex header-menu__burger"
              onClick={() => onHideMenu()}
            >
              <BaseSvg name="ico-close" />
            </button>
          </div>
        </div>
        <div className="header-menu__body">
          <ul className="header-menu__items">
            {items.map((item) => (
              <li key={item.link}>
                <AppLink
                  isNavLink
                  onClick={() => onHideMenu()}
                  className="header-menu__item"
                  to={item.link}
                >
                  {item.name}
                </AppLink>
              </li>
            ))}
          </ul>

          <ListLang className="header-menu__lang" />

          <AppLink
            to={
              i18n.language === 'ru'
                ? '/regulations/pravyla-poseshhenyya-sportyvnogo-kluba-tehnyka-bezopasnosty/'
                : '/regulations/pravyla-vidviduvannya-sportyvnogo-klubu-tehnika-bezpeky/'
            }
            onClick={() => onHideMenu()}
            className="header-menu__rules"
          >
            {t('visiting_rules')}
          </AppLink>
        </div>
      </Container>
      <div className="header-menu__bg">
        <img src={welcome_gym_m} alt="img" className="header-menu__bg-img" />
      </div>
    </div>
  )
}

export default HeaderMenu
