import React from 'react'
import { BaseSvg } from '../../index'
import './ClubListTitle.scss'
import { useTranslation } from 'react-i18next'

const ClubListTitle = () => {
  const { t } = useTranslation()

  return (
    <ul className="list-title">
      <li className="list-title__item ">
        <BaseSvg name="ico-visa" />
        Visa/MasterCard
      </li>
      <li className="list-title__item ">
        <BaseSvg name="ico-conditioner" />
        {t('air_conditioning')}
      </li>
      <li className="list-title__item ">
        <BaseSvg name="ico-wifi" />
        Wi-fi
      </li>
    </ul>
  )
}

export default ClubListTitle
