import React, { FC, useState } from 'react'
import Modal from 'react-modal'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { ButtonSlider, Container } from '../../index'
import './ClubSlider.scss'
import { IMedia } from '../../../models/Clubs'
import windowDimensions from '../../../hooks/windowDimensions'
import Gallery from '../../Gallery/Gallery'
import PaginationFraction from '../../Base/PaginationFraction/PaginationFraction'

interface IProps {
  className?: string
  media: IMedia[]
}

SwiperCore.use([Navigation, Pagination])

const overlayStyle: React.CSSProperties = {
    backgroundColor: 'rgba(7,7,19,0.55)',
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}

const contentStyle: React.CSSProperties = {
    padding: 0,
    position: 'static',
    border: 'none',
    color: 'inherit',
    backgroundColor: 'transparent',
    borderRadius: 0,
    overflow: 'visible',
}

const basicStyleModal = {
  overlay: overlayStyle,
  content: contentStyle,
}

const ClubSlider: FC<IProps> = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const { windowWidth, md } = windowDimensions()
  const [activeSlide, setActiveSlide] = useState<null | number>(null)

  function closeModal() {
    setIsOpen(false)
    setActiveSlide(null)
  }

  function openModal(id: number) {
    if (windowWidth < md) return
    setIsOpen(true)
    setActiveSlide(id)
  }

  return (
    <div className={`club-slider ${props.className}`}>
      <Container>
        <Swiper
          className="club-slider__slider"
          slidesPerView="auto"
          navigation={{
            nextEl: '.club-slider__btn-next',
            prevEl: '.club-slider__btn-prev',
          }}
          pagination={{
            type: 'fraction',
            el: '.club-slider__pagination',
            clickable: true,
          }}
        >
          {props.media.map((item: IMedia) => (
            <SwiperSlide
              key={item.id}
              onClick={() => openModal(item.id)}
              className="club-slider__slide"
            >
              <img
                className="club-slider__slide-img"
                src={item.original_url}
                alt={item.created_at}
              />
            </SwiperSlide>
          ))}
          <PaginationFraction className="club-slider__pagination" />
          <ButtonSlider
            type="next"
            className="club-slider__btn-prev club-slider__btn"
          />
          <ButtonSlider
            type="prev"
            className="club-slider__btn-next club-slider__btn"
          />
        </Swiper>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Write a feedback"
        closeTimeoutMS={200}
        preventScroll={true}
        style={basicStyleModal}
        bodyOpenClassName="_scroll-lock"
        portalClassName="modal-portal"
      >
        {props?.media && (
          <Gallery images={props.media} activeSlide={activeSlide} />
        )}
      </Modal>
    </div>
  )
}

export default ClubSlider
