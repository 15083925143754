import React, { FC, useState, useRef, useEffect } from 'react'
import { BaseSvg } from '../../index'
import useOutsideClick from '../../../hooks/outsideAlerter'
import './BaseSelect.scss'
import { IClubs } from '../../../models/Clubs'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'

interface IProps {
  className?: string
  options: IClubs[]
  value?: string
  name: string
  register: object
  callBack: any
  errors: string[]
}

const BaseSelect: FC<IProps> = ({
  className = '',
  options,
  value,
  register,
  name,
  callBack,
  errors,
}) => {
  const now = moment()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<any>(value)
  const [selectedLabel, setSelectedLabel] = useState<null | IClubs | undefined>(
    null
  )
  const selectRef = useRef(null)

  useOutsideClick(selectRef, () => {
    setIsOpen(false)
  })

  const choiceItem = (id: number) => {
    setSelected(id)
    setIsOpen(false)
  }

  useEffect(() => {
    const element = document.getElementById(name)
    if (element) {
      ;(element as HTMLInputElement).value = selected as string
    }

    setSelectedLabel(options.find((el: IClubs) => el.id === selected))
    setIsOpen(false)
    callBack(selected)
  }, [selected])

  return (
    <div ref={selectRef} className={`base-select ${className}`}>
      <select
        {...register}
        id={name}
        name={name}
        className="base-select__html-select"
      >
        {options.map((item: IClubs) =>
          moment(item.created_at) < now ? (
            <option key={item.id} value={item.id}>
              {item.address}
            </option>
          ) : null
        )}
      </select>
      <div className="base-select__inner">
        <button
          type="button"
          onClick={() => setIsOpen((prev: boolean) => !prev)}
          className="base-select__trigger"
        >
          <span className="base-select__trigger-inner">
            {selectedLabel
              ? `${selectedLabel.name} ${selectedLabel.address}`
              : t('choose_a_club')}
          </span>
          <BaseSvg name="ico-arrow-down" className="base-select__trigger-svg" />
        </button>
        {isOpen && (
          <div className="base-select__dropdown">
            <ul className="base-select__dropdown-list">
              {options && options.length
                ? options.map((item: IClubs) =>
                    moment(item.created_at) < now ? (
                      <li
                        key={item.id}
                        onClick={() => {
                          choiceItem(item.id)
                        }}
                        className="base-select__dropdown-item"
                      >
                        <div className="base-select__dropdown-title">
                          {item.name}
                          <BaseSvg name="ico-arrow-right-long" />
                        </div>
                        <ul className="base-select__dropdown-sub-list">
                          <li className="base-select__dropdown-sub-item">
                            <button
                              type="button"
                              className="base-select__dropdown-sub-btn"
                            >
                              {item.address}
                            </button>
                          </li>
                        </ul>
                      </li>
                    ) : null
                  )
                : ''}
            </ul>
          </div>
        )}
      </div>
      {errors && errors[name] && (
        <ul className="base-select__errors">
          {errors[name].map((el, index) => (
            <li key={index} className="base-select__errors-item">
              * {el}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default BaseSelect
