import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IPagination } from '../../models/Pagination'
import { IBlogs } from '../../models/Blogs'

interface IPayloadParams {
  page?: number
  tag?: string
}

export const fetchBlogs = createAsyncThunk(
  'blogs/fetchBlogs:load',
  async function (payload: IPayloadParams) {
    const params = {
      page: payload.page ?? 1,
      tag: payload.tag ?? '',
    }

    const response = await api.get('blogs', { params })
    return response.data
  }
)

interface IBlogsState {
  data: IBlogs[]
  pagination: IPagination | null
}

const initialState = {
  data: [],
  pagination: null,
} as IBlogsState

const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBlogs.fulfilled, (state, action) => {
      state.data = action.payload.data as IBlogs[]
      state.pagination = action.payload.meta as IPagination
    })
  },
})

export const blogsReducers = blogsSlice.reducer
