import React from 'react'
import './Error.scss'
import { Button, Container } from '../../components'
import { useTranslation } from 'react-i18next'

const Error = () => {
  const { t } = useTranslation()

  return (
    <div className="error">
      <Container>
        <div className="error__inner">
          <div className="error__code">{t('error_404')}</div>
          <div className="error__title">{t('page_not_found')}</div>
          <div className="error__description">
            {t(
              'the_address_is_typed_incorrectly_or_this_page_is_no_longer_on_the_site_exist'
            )}
          </div>
          <Button to={'/'}>{t('go_to_home_page')}</Button>
        </div>
      </Container>
    </div>
  )
}

export default Error
