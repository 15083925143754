import React, { FC } from 'react'
import './ClubSchedule.scss'
import { BaseSvg, ButtonArrow, Container } from '../../index'
import ClubScheduleTable from './ClubScheduleTable'
import { useTranslation } from 'react-i18next'

interface IProps {
  link: string
  callBack?: any
}

const ClubSchedule: FC<IProps> = (props) => {
  const { t } = useTranslation()

  const handlerToBLock = () => {
    props.callBack()
  }

  return (
    <div className="club-schedule">
      <Container>
        <div className="club-schedule__body">
          <div className="club-schedule__title">
            <h2 className="title-club">{t('timetable')}</h2>
            <ButtonArrow link={props.link}>{t('price_list')}</ButtonArrow>
          </div>
          <ClubScheduleTable />
        </div>
      </Container>
      <button onClick={handlerToBLock} className="club-schedule__schedule">
        <Container>
          <div className="club-schedule__schedule-body">
            <BaseSvg name="ico-lightning" />
            <span className="club-schedule__schedule-link">
              {t('see_class_schedule')}
            </span>
            <BaseSvg name="ico-lightning" />
          </div>
        </Container>
      </button>
    </div>
  )
}

export default ClubSchedule
