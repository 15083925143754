import { useLocation, useNavigate } from 'react-router-dom'

const useScrollTo = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  function scrollTo(page: number, top = 0): void {
    window.scrollTo({ top })
    if (page > 1) {
      navigate({
        pathname,
        search: `?page=${page}`,
      })
    } else {
      navigate(pathname)
    }
  }

  return { scrollTo }
}

export default useScrollTo
