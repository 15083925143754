import React, { useEffect, useState } from 'react'
import './Header.scss'
import { BaseSvg, Container } from '../index'
import HeaderNav from './HeaderNav'
import HeaderMenu from './HeaderMenu'
import ListLang from '../ListLang/ListLang'
import AppLink from '../Base/AppLink'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const Header = ({ className = '' }: { className: string }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isShowBtnBack, setIsShowBtnBack] = useState(false)
  const { t, i18n } = useTranslation()
  const schedule = (window as any).setting[`schedule_${i18n.language}`]
    ? (window as any).setting[`schedule_${i18n.language}`].split('|')
    : []
  const [showMenu, setShowMenu] = useState(false)
  const bodyEl = document.querySelector('body')
  const rootEl = document.getElementById('root')
  const onShowMenu = () => {
    setShowMenu(true)
    if (bodyEl) {
      bodyEl.classList.add('_scroll-lock')
    }
    if (rootEl) {
      rootEl.classList.add('_show-menu')
    }
  }

  const onHideMenu = () => {
    setShowMenu(false)
    if (bodyEl) {
      bodyEl.classList.remove('_scroll-lock')
    }

    if (rootEl) {
      rootEl.classList.remove('_show-menu')
    }
  }

  useEffect(() => {
    if (
      pathname.includes('clubs-price') ||
      pathname.includes('clubs-schedule') ||
      pathname.includes('trainer')
    ) {
      setIsShowBtnBack(true)
    } else {
      setIsShowBtnBack(false)
    }
  }, [pathname])

  return (
    <header className={`header ${className}`}>
      <Container>
        <div className="header__body">
          <AppLink to="/" className="header__logo">
            <BaseSvg name="ico-logo" />
          </AppLink>
          <div className="header__text _mw d-lg-none">
            {t('network_of_sports_clubs_in_Kharkov')}
          </div>
          <HeaderNav />

          <ListLang className="d-md-none" />

          <div className="header__text _ml d-md-none">
            {schedule.map((item: string, index: number) => (
              <span key={index}>{item}</span>
            ))}
          </div>

          {isShowBtnBack && (
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="header__mobile-back d-none d-md-flex"
            >
              <BaseSvg name="ico-arrow-right" />
            </button>
          )}

          <button
            className="header__burger d-none d-md-flex"
            onClick={() => onShowMenu()}
          >
            <BaseSvg name="ico-burger" />
          </button>
        </div>
      </Container>
      <HeaderMenu showMenu={showMenu} onHideMenu={onHideMenu} />
    </header>
  )
}

export default Header
