import React, {memo, useEffect, useState} from 'react'
import './Home.scss'
import {
    HomeBlog,
    HomeClubs,
    HomeCorporate,
    HomeMap,
    HomeReviews,
    HomeSmartFit,
    HomeWelcome,
    Ticker,
} from '../../components'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {RootState} from '../../store'
import {IClubs} from '../../models/Clubs'
import {fetchClubs} from '../../store/slices/clubs'
import {fetchFeedback} from '../../store/slices/feedback'
import {fetchBlogs} from '../../store/slices/blogs'
import {useTranslation} from 'react-i18next'
import {Navigate, useLocation} from 'react-router-dom'
import {IMarkers} from '../../models/Markers'
import {fetchSettings} from "../../store/slices/settings";

const Index = () => {
    const mapUrlKey = 'main_google_map_url'
    const location = useLocation()
    const {i18n} = useTranslation()
    const dispatch = useAppDispatch()
    const {clubs, polygon} = useAppSelector((state: RootState) => state.clubs)
    const {settings} = useAppSelector((state: RootState) => state.settings)
    const {data: feedback} = useAppSelector(
        (state: RootState) => state.feedback
    )
    const {data: blogs} = useAppSelector((state: RootState) => state.blogs)
    const [markers, setMarkers] = useState<IMarkers[]>([])

    const mapUrl = (settings.find((el) => el.key === mapUrlKey)?.value)

    useEffect(() => {
        if (!clubs.length) return
        const result: IMarkers[] = []

        clubs.forEach((el: IClubs) => {
            result.push({
                id: el.id,
                title: el.name,
                address: el.address,
                slug: el.slug,
                position: {
                    lat: +el.lat,
                    lng: +el.lng,
                },
            })
        })
        setMarkers(result)
    }, [clubs])

    useEffect(() => {
        if (!clubs.length) {
            dispatch(fetchClubs())
        }

        dispatch(fetchFeedback(1))

        dispatch(fetchSettings())

        if (!blogs.length) {
            const page = 1
            const tag = ''
            dispatch(fetchBlogs({page, tag}))
        }
    }, [])

    if (
        i18n.language === 'ru' &&
        (location.pathname === '/ru/' || location.pathname === '/ru')
    ) {
        return <Navigate replace to="glavnaya/"/>
    }

    return (
        <div className="home-page">
            <HomeWelcome/>
            <HomeClubs clubs={clubs}/>
            {markers?.length && polygon?.length ? (
                <HomeMap polygon={polygon} markers={markers} mapUrl={mapUrl}/>
            ) : null}
            <HomeCorporate/>
            <Ticker className="home-page__ticker d-md-none"/>
            <HomeSmartFit/>
            {blogs?.length ? <HomeBlog blogs={blogs}/> : null}
            <Ticker className="home-page__ticker d-none d-md-block"/>
            <HomeReviews feedback={feedback}/>
        </div>
    )
}

export default memo(Index)
