import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IRegulations } from '../../models/Regulations'

export const fetchRegulations = createAsyncThunk(
  'regulations/fetchRegulations:load',
  async function (id: string | undefined) {
    if (!id) return
    const response = await api.get(`page/${id}`)
    return response.data
  }
)

interface regulationsState {
  regulations: null | IRegulations
}

const initialState = {
  regulations: null,
} as regulationsState

const regulationsSLice = createSlice({
  name: 'regulations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRegulations.fulfilled, (state, action) => {
      state.regulations = action.payload.data
    })
  },
})

export const regulationsReducers = regulationsSLice.reducer
