import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import './Regulations.scss'
import Promo from '../../components/Promo/Promo'
import { useParams } from 'react-router-dom'
import { BaseSvg, Container } from '../../components'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchRegulations } from '../../store/slices/regulations'
import { RootState } from '../../store'
import TickerReact from '../../components/TickerReact/TickerReact'
import AppLink from '../../components/Base/AppLink'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const Regulations = () => {
  const { t } = useTranslation()
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbs[]>([])
  const dispatch = useAppDispatch()
  const { hash } = useLocation()
  const { id } = useParams()
  const { regulations } = useAppSelector(
    (state: RootState) => state.regulations
  )

  useEffect(() => {
    dispatch(fetchRegulations(id))
  }, [])

  useEffect(() => {
    if (regulations) {
      setBreadCrumbs([
        {
          id: regulations.id,
          name: regulations.name,
        },
      ])
    }
  }, [regulations])

  const goTOBlock = () => {
    const arrHash = hash?.split('#')
    if (!arrHash.some((el) => el)) return
    const HashGoTO = arrHash[arrHash.length - 1]
    const gotoBlock = document?.querySelector(`#${HashGoTO}`)
    if (gotoBlock) {
      const gotoBlockValue = gotoBlock.getBoundingClientRect().top + pageYOffset

      window.scrollTo({
        top: gotoBlockValue,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (!regulations) return
    goTOBlock()
  }, [regulations])

  return (
    <div className="regulations">
      <Promo title={t('visiting_rules')}>
        <AppLink
          to="/clubs/"
          className="regulations__btn btn _strong-italic d-md-none"
        >
          <span>{t('see_our_clubs')}</span>
          <BaseSvg name="ico-arrow-right-long" />
        </AppLink>
      </Promo>
      <BreadCrumbs dataList={breadCrumbs} />
      <Container>
        {regulations && (
          <div className="regulations__inner">
            {ReactHtmlParser(regulations.text)}
          </div>
        )}
      </Container>
      <TickerReact className="_mb-2 d-md-none" />
    </div>
  )
}

export default Regulations
