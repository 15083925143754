import { Action, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface ILoading {
  refCount: number
  isGlobalPending: boolean
}

const initialState = {
  refCount: 0,
  isGlobalPending: false,
} as ILoading

function globalPending(state: ILoading, pending: boolean): void {
  if (pending) {
    state.refCount++
    state.isGlobalPending = true
  } else if (state.refCount > 0) {
    state.refCount--
    state.isGlobalPending = state.refCount > 0
  }
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(
        (action: Action) =>
          action.type.includes('/pending') && action.type.includes(':load'),
        (state) => {
          globalPending(state, true)
        }
      )
      .addMatcher(
        (action: Action) =>
          action.type.includes('/fulfilled') && action.type.includes(':load'),
        (state) => {
          globalPending(state, false)
        }
      )
      .addMatcher(
        (action: Action) =>
          action.type.includes('/rejected') && action.type.includes(':load'),
        (state, action) => {
          if (action?.payload?.message) {
            toast.error(action?.payload?.message)
          }
          globalPending(state, false)
        }
      ),
})

export const loadingReducers = loadingSlice.reducer
