import React, { FC } from 'react'
import { BaseSvg, Container } from '../../index'
import './ClubServices.scss'
import { IServices } from '../../../models/Clubs'
import { useTranslation } from 'react-i18next'

interface IProps {
  services: IServices[]
}

const ClubServices: FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <div className="club-services">
      <Container>
        <div className="club-services__body">
          <h2 className="club-services__title title-club d-md-none">
            {t('services')}
          </h2>
          <ul className="club-services__items">
            {props.services.map((item: IServices, index: number) => (
              <li key={index} className="club-services__item">
                <BaseSvg name="ico-check" className="club-services__item-svg" />
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  )
}

export default ClubServices
