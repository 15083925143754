import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import './ListLang.scss'
import { Helmet } from 'react-helmet'
import { languages } from '../../settings/languages'
import { useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'

interface IProps {
  className?: string
}

const ListLang: FC<IProps> = (props) => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const { id } = useParams()
  const { club } = useAppSelector((state: RootState) => state.club)
  const { blog } = useAppSelector((state: RootState) => state.blog)
  const { regulations } = useAppSelector(
    (state: RootState) => state.regulations
  )
  const pathnameWithoutLanguage = pathname.replace(/\/ru|\/ua/g, '')

  const [uk, setUk] = useState<any>('')
  const [ru, setRu] = useState<any>('')

  const changeLanguageHandler = (lang: string) => {
    const mainPreloader = document.querySelector<HTMLElement>('.preloader-page')

    if (mainPreloader) {
      mainPreloader.style.opacity = '1'
      mainPreloader.style.display = 'flex'
      mainPreloader.style.background = 'white'
    }

    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    if (
      pathname.split('/').includes('club') &&
      pathname !== '/' &&
      pathname !== '/ru/glavnaya/' &&
      club &&
      club?.slug === id
    ) {
      setUk(`/club/${club?.slugs?.uk}/`)
      setRu(`/club/${club?.slugs?.ru}/`)
    } else if (
      pathname.split('/').includes('gallery') &&
      pathname !== '/' &&
      pathname !== '/ru/glavnaya/' &&
      club &&
      club?.slug === id
    ) {
      setUk(`/gallery/${club?.slugs?.uk}/`)
      setRu(`/gallery/${club?.slugs?.ru}/`)
    } else if (
      blog?.slug &&
      blog?.slug === id &&
      pathname !== '/' &&
      pathname !== '/ru/glavnaya/'
    ) {
      setUk(`/${blog?.slugs?.uk}/`)
      setRu(`/${blog?.slugs?.ru}/`)
    } else if (
      pathname.split('/').includes('regulations') &&
      pathname !== '/' &&
      pathname !== '/ru/glavnaya/' &&
      regulations &&
      regulations?.slug === id
    ) {
      setUk(`/regulations/${regulations?.slugs?.uk}/`)
      setRu(`/regulations/${regulations?.slugs?.ru}/`)
    } else if (
      pathname !== '/' &&
      pathname !== '/ru/glavnaya/' &&
      (pathname === '/ru/reabilitacionnyj-centr' ||
        pathname === '/reabilitaciynyi-centr-saltivka')
    ) {
      setUk(`reabilitaciynyi-centr-saltivka`)
      setRu(`/reabilitacionnyj-centr`)
    } else {
      setRu(null)
      setUk(null)
    }
  }, [pathname, club, id, blog, regulations])

  return (
    <>
      <Helmet>
        {pathname !== '/ru/reabilitacionnyj-centr' &&
        pathname !== '/reabilitaciynyi-centr-saltivka'
          ? languages.languages.map((el) => (
              <link
                key={el.id}
                rel="alternate"
                hrefLang={el.locale}
                href={`https://pulse-gym.com.ua${
                  el.show_code === languages.defaultLanguageCode
                    ? pathnameWithoutLanguage === '/glavnaya/' ||
                      pathnameWithoutLanguage === '/glavnaya'
                      ? ''
                      : uk
                      ? uk
                      : pathnameWithoutLanguage === '/'
                      ? ''
                      : pathnameWithoutLanguage
                    : `/${el.locale}${
                        pathnameWithoutLanguage === '/'
                          ? '/glavnaya/'
                          : ru
                          ? ru
                          : pathnameWithoutLanguage
                      }`
                }`}
              />
            ))
          : languages.languages.map((el) => (
              <link
                key={el.id}
                rel="alternate"
                hrefLang={el.locale}
                href={`https://pulse-gym.com.ua${
                  el.show_code === languages.defaultLanguageCode
                    ? pathnameWithoutLanguage === '/glavnaya/' ||
                      pathnameWithoutLanguage === '/glavnaya'
                      ? ''
                      : uk
                      ? uk
                      : pathnameWithoutLanguage
                    : `/${el.locale}${
                        pathnameWithoutLanguage === '/'
                          ? '/glavnaya/'
                          : ru
                          ? ru
                          : pathnameWithoutLanguage
                      }`
                }`}
              />
            ))}
      </Helmet>
      <ul className={`list-lang ${props.className}`}>
        {pathname !== '/ru/reabilitacionnyj-centr' &&
        pathname !== '/reabilitaciynyi-centr-saltivka'
          ? languages.languages.map((el) => (
              <li key={el.id} className="list-lang__item">
                <a
                  href={
                    el.show_code === languages.defaultLanguageCode
                      ? pathnameWithoutLanguage === '/glavnaya/' ||
                        pathnameWithoutLanguage === '/glavnaya'
                        ? '/'
                        : uk
                        ? uk
                        : pathnameWithoutLanguage
                      : `/${el.locale}${
                          pathnameWithoutLanguage === '/'
                            ? '/glavnaya/'
                            : ru
                            ? ru
                            : pathnameWithoutLanguage
                        }`
                  }
                  onClick={() => changeLanguageHandler(el.show_code)}
                  className={`list-lang__btn ${
                    i18n.language === el.show_code ? '_active' : ''
                  }`}
                >
                  <img
                    src={require(`../../assets/image/lang/${el.show_code}.svg`)}
                    alt="img"
                    className="list-lang__btn-img"
                  />
                </a>
              </li>
            ))
          : languages.languages.map((el) => (
              <li key={el.id} className="list-lang__item">
                <a
                  href={
                    el.show_code === languages.defaultLanguageCode
                      ? '/reabilitaciynyi-centr-saltivka/'
                      : '/ru/reabilitacionnyj-centr/'
                  }
                  onClick={() => changeLanguageHandler(el.show_code)}
                  className={`list-lang__btn ${
                    i18n.language === el.show_code ? '_active' : ''
                  }`}
                >
                  <img
                    src={require(`../../assets/image/lang/${el.show_code}.svg`)}
                    alt="img"
                    className="list-lang__btn-img"
                  />
                </a>
              </li>
            ))}
      </ul>
    </>
  )
}

ListLang.defaultProps = {
  className: '',
}

export default ListLang
